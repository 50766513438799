/* You can add global styles to this file, and also import other style files */
@import '@angular/material/theming';

@include mat-core();
$app-primary: mat-palette($mat-indigo);
$app-accent: mat-palette($mat-pink);
$app-warn: mat-palette($mat-red);

// Custom Sass colors vars (will be available in all the project)
$primary: mat-color($app-primary);
$accent: mat-color($app-accent);
$warn: mat-color($app-warn);

$theme: mat-light-theme($app-primary, $app-accent, $app-warn);

@include angular-material-theme($theme);

@import './node_modules/@mat-datetimepicker/core/datetimepicker/datetimepicker-theme.scss';

// Using the $theme variable from the pre-built theme you can call the theming function
@include mat-datetimepicker-theme($theme);

#weekDatePicker {
  input {
    visibility: hidden;
  }

  button {
    margin-left: -45px;
    color: #adadad;
    position: absolute;
    margin-top: -4px;
  }

  mat-icon {
    width: 18px;
    height: 18px;
  }

  div.mat-form-field-underline {
    height: 0px !important;
  }

  &.daycalendarweek {
    button {
      margin-left: -73px;
      position: absolute;
      margin-top: -33px;
    }
  }

}

app-timetable-weekday flutaro-datetimepicker#weekDatePicker div.date-picker-component {
  width: 73px;
}

#jobPlanned {
  button.mat-icon-button {
    margin-top: 20px;
    color: rgba(0, 0, 0, .54);
  }

  .jobPlannedDatepicker div.mat-form-field-underline {
    height: 0px !important;
  }

  mat-icon {
    width: 23px;
    height: 23px;
  }
}

#jobTableDaterange {
  input {
    color: #d9d9d6 !important;
    font-size: 14px;
    position: relative;
    top: -3px;
  }
}

#job_repeating {
  button.mat-icon-button {
    color: #adadad;
  }

  mat-icon {
    height: 18px;
    width: 18px;
  }
}

#weekday-time-datepicker {
  // width: 30%;
  mat-datetimepicker-toggle {
    margin-left: -24px !important;
  }

  mat-icon {
    width: 20px;
    height: 20px;
  }
}

#dashboardDaterange {
  app-datetimepicker {
    padding-top: 30px;
  }
}

#news_date_picker {
  button {
    color: #707372;
  }

  mat-icon {
    width: 18px;
    height: 18px;
  }
}

.timetableDriverList cdk-virtual-scroll-viewport {
  overflow-y: hidden;
}

.timetableDriverList cdk-virtual-scroll-viewport {
  overflow-y: hidden !important;
}

.room-timeline cdk-virtual-scroll-viewport {
  overflow-y: hidden !important;
  height: 550px;
}

#driverSectionAside {
  cdk-virtual-scroll-viewport {
    height: 550px;
    overflow-x: hidden;
  }
}

#side-nav-job::-webkit-scrollbar, #unplannedJobView::-webkit-scrollbar, .sidenavFixedHeader::-webkit-scrollbar {
  width: 4px;
}

#side-nav-job::-webkit-scrollbar-button, #unplannedJobView::-webkit-scrollbar-button, .sidenavFixedHeader::-webkit-scrollbar-button {
  width: 4px;
  height: 2px;
}

#side-nav-job::-webkit-scrollbar-thumb, #unplannedJobView::-webkit-scrollbar-thumb, .sidenavFixedHeader::-webkit-scrollbar-thumb {
  background: #c5c7c6; /* Old browsers */
  background: -moz-linear-gradient(top, #c5c7c6 0%, #b1b3b2 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(top, #c5c7c6 0%, #b1b3b2 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom, #c5c7c6 0%, #b1b3b2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c5c7c6', endColorstr='#b1b3b2', GradientType=0); /* IE6-9 */
  border-radius: 5px;
}

#side-nav-job::-webkit-scrollbar-thumb:hover, #unplannedJobView::-webkit-scrollbar-thumb:hover, .sidenavFixedHeader::-webkit-scrollbar-thumb:hover {
  background: #ff8cb3; /* Old browsers */
  background: -moz-linear-gradient(left, #ff8cb3 0%, #ffa3c2 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(left, #ff8cb3 0%, #ffa3c2 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to right, #ff8cb3 0%, #ffa3c2 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff8cb3', endColorstr='#ffa3c2', GradientType=1); /* IE6-9 */
}

.unplannedDropZone {
  height: 40px !important;
  overflow: hidden !important;
  background: none !important;
  border: none !important;

  .newJobsDragAndDropIcon {
    background: none !important;
  }
}

.matExpand {
  height: 200px;
}

.matCollapsed {
  height: 350px;
}

#job-display {
  .mat-option-text {
    text-align: center;
  }

  .mat-select-value-text {
    color: white;
  }
}

#job-display-options {
  color: white;
  text-align: center;
}

app-date-range .mat-form-field-flex {
  padding-bottom: 0px !important;
  padding-top: 0px !important;
}

app-date-range mat-label.daterangelabel {
  font-size: 16px;
  position: relative;
  top: -5px;
}

app-job-table app-date-range mat-label.daterangelabel {
  position: absolute;
  top: -3px;
  color: #bbbcbc;
}

app-job-table app-date-range mat-form-field .mat-form-field-infix {
  padding: 0px !important;
}

.mat-form-field-type-mat-date-range-input {
  padding: 0px;
}

.dashboardDispatcher app-date-range mat-label.daterangelabel {
  font-size: 14px;
  position: absolute;
  top: -1px;
}

#jobTableDataFirstToolbar app-date-range .mat-date-range-input-separator {
  margin-right: 20px;
}

app-date-range mat-datepicker-toggle {
  font-size: 16px;
  position: relative;
}

app-date-range mat-datepicker-toggle.mat-datepicker-toggle-active {
  color: #ff4081
}

.dashboardDispatcher mat-date-range-input input.input_flutaro {
  width: 72px;
}
